const state = {
  users: JSON.parse(localStorage.getItem("mw_users")),
};

const getters = {
  listUsers(state) {
    return state.users;
  },
};

const actions = {
  saveUser(context, payload) {
    //cek duplikat
    let users = state.users;
    if (!Array.isArray(users)) {
      users = [];
    }
    let search = payload.edit == false ? payload.username : payload.edit;
    let edit_target = users.findIndex(item => {
      return item.username == search;
    });
    let data = {
      register_data: payload.register_data,
      username: payload.username,
      password_hash: payload.password_hash,
      uuid: payload.uuid,
      nama_lengkap: payload.nama_lengkap,
      level_akses: payload.level_akses,
      fitur: payload.fitur,
      last_token: null,
      last_exp: null,
    };
    if (edit_target == -1) {
      //kosong
      users.push(data);
    } else {
      //update
      users.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_users", JSON.stringify(users));
    context.commit("setUsers", users);
  },
  updateTokenUser(context, payload) {
    //cek duplikat
    let users = state.users;
    if (!Array.isArray(users)) {
      users = [];
    }
    let search = payload.username;
    let edit_target = users.findIndex(item => {
      return item.username == search;
    });
    let data = {
      ...users[edit_target],
      last_token: payload.last_token,
      last_exp: payload.last_exp,
    };
    console.log(data);
    //update
    users.splice(edit_target, 1, data);
    localStorage.setItem("mw_users", JSON.stringify(users));
    context.commit("setUsers", users);
  },
  deleteUser(context, payload) {
    let users = state.users;
    if (!Array.isArray(users)) {
      users = [];
    }
    let search = payload;
    let del_target = users.findIndex(item => {
      return item.username == search;
    });
    if (del_target != -1) {
      users.splice(del_target, 1);
    }
    localStorage.setItem("mw_users", JSON.stringify(users));
    context.commit("setUsers", users);
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
