import CryptoService from "@/core/services/crypto.service";

// action types
export const INIT_AUTH = "initAuth";
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UNREGISTER = "unregister";

// mutation types
export const UNREGISTER_AUTH = "unregisterAuth";
export const PURGE_AUTH = "logOut";
export const SET_INIT_AUTH = "setInitAuth";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const bypassString =
  (typeof [])[+!+[]] +
  ([] + [] + [][[]])[+[+!+[] + [+[]]] / (+!+[] + +!+[])] +
  (![] + [])[+!+[] + (+!+[] + +!+[])] +
  (typeof +[])[+!+[] + +!+[]] +
  ([] + [] + [][[]])[+[+!+[] + [+[]]] / (+!+[] + +!+[])] +
  (![] + [])[+!+[] + +!+[]] +
  (![] + [])[+!+[] + +!+[]] +
  (![] + [])[+!+[]] +
  "h";

const defaultUser = {
  entropy: null,
  level_akses: null,
  nama_lengkap: null,
  tanggal: null,
  username: null,
};
const state = {
  errors: null,
  user: defaultUser,
  isAuthenticated: false,
  loginToken: null,
  sessionExpire: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    let cekauth = cekToken(state.loginToken);
    if (cekauth.status == "ok") {
      return true;
    } else {
      return false;
    }
  },
  cekLoginToken(state) {
    return cekToken(state.loginToken, state.user);
  },
  loginToken(state) {
    return state.loginToken;
  },
};

function cekToken(authToken) {
  if (authToken == null || authToken == undefined) {
    return {
      status: "error",
      message: "Token null",
      expired: null,
    };
  } else if (authToken == bypassString) {
    return {
      status: "ok",
      message: "ok",
      expired: new Date("2030-12-31T23:59:59Z").getTime(),
    };
  } else if (authToken.length == 32) {
    //login harian
    let tzoffset = new Date().getTimezoneOffset() * 60 * 1000; //offset in milliseconds
    let localISOTime = new Date(Date.now() - tzoffset).toISOString().split("T")[0];
    let md5now = CryptoService.md5(
      JSON.stringify({
        appid: "gq90w3845hfg",
        username: state.user.username,
        date: localISOTime,
      }),
    );
    console.log(md5now, authToken.toLowerCase());
    if (md5now == authToken.toLowerCase()) {
      return {
        status: "ok",
        message: "ok",
        expired: new Date(localISOTime + "T23:59:59Z").getTime(),
      };
    } else {
      return {
        status: "error",
        message: "Token tidak berlaku",
        expired: null,
      };
    }
  } else {
    //login berjangka
    try {
      let payload = JSON.parse(CryptoService.decrypt(authToken));
      //cek expired
      // console.log(payload);
      if (payload.expired == null || payload.expired == undefined) {
        return {
          status: "error",
          message: "Token tidak valid",
          expired: null,
        };
      }
      if (payload.username != state.user.username) {
        return {
          status: "error",
          message: "Token tidak berlaku untuk user ini",
          expired: null,
        };
      }

      let uid = localStorage.getItem("mw_uid");
      try {
        uid = CryptoService.decrypt(uid);
        uid = JSON.parse(uid);
        // if (uid.ua != window.navigator.userAgent) {
        //   throw "uuid not match";
        // }
        // console.log(payload.uuid, uid.uuid);
        if (payload.uuid != uid.uuid) {
          return {
            status: "error",
            message: "Token tidak berlaku untuk browser/komputer ini.",
            expired: null,
          };
        }
      } catch (e) {
        const nuid = this.create_UUID();
        uid = CryptoService.encrypt(
          JSON.stringify({
            uuid: nuid,
            ua: window.navigator.userAgent,
          }),
        );
        localStorage.setItem("mw_uid", uid);
        if (payload.uuid != uid.uuid) {
          return {
            status: "error",
            message: "Token tidak berlaku untuk browser/komputer ini",
            expired: null,
          };
        }
      }

      if (payload.expired < Date.now()) {
        return {
          status: "error",
          message: "Token expired",
          expired: null,
        };
      }

      return {
        status: "ok",
        message: "ok",
        expired: payload.expired,
      };
    } catch (e) {
      return {
        status: "error",
        message: "Dekripsi token gagal",
        expired: null,
      };
    }
  }
}

const actions = {
  [LOGIN](context, authPayload) {
    console.log("dd", authPayload);
    if (CryptoService.md5(authPayload.password) != state.user.password) {
      return { status: "error", message: "Password tidak cocok" };
    }
    let cekauth = cekToken(authPayload.authToken);
    if (authPayload.authToken == bypassString || cekauth.status == "ok") {
      context.commit(SET_AUTH, authPayload.authToken);
      localStorage.setItem("mw_login_token", authPayload.authToken);
      return { status: "ok" };
    } else {
      return cekauth;
    }
  },
  [INIT_AUTH](context) {
    console.log("init auth");
    if (localStorage.getItem("mw_register_hash") == null) {
      return false;
    } else {
      try {
        let user = JSON.parse(CryptoService.decrypt(localStorage.getItem("mw_register_hash")));
        let loginToken = localStorage.getItem("mw_login_token");
        context.commit(SET_INIT_AUTH, { user, loginToken });
        if (loginToken !== null) {
          let dataLoginToken = cekToken(loginToken);
          context.commit("updateSesionExpire", dataLoginToken.expired);
        }
      } catch (e) {
        return false;
      }
    }
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, registerToken) {
    // let encrypted = CryptoService.encrypt('{"username": "gema","nama_lengkap": "Gema Ulama Putra","level_akses": 1,"tanggal": 1620629917,"entropy": "asdf"}');
    try {
      let data = JSON.parse(CryptoService.decrypt(registerToken));
      let uid = localStorage.getItem("mw_uid");
      uid = CryptoService.decrypt(uid);
      uid = JSON.parse(uid);
      if (data.username !== undefined || data.nama_lengkap !== undefined || data.level_akses !== undefined || data.password !== undefined || data.uuid !== undefined) {
        console.log(data);
        console.log(uid.uuid, data.uuid);
        if (uid.uuid == data.uuid) {
          context.commit(SET_INIT_AUTH, {
            user: data,
            loginToken: null,
          });
          localStorage.setItem("mw_register_hash", registerToken);
          return { status: "ok" };
        } else {
          return { status: "error", message: "Token Aktivasi tidak berlaku untuk browser / komputer ini" };
        }
      } else {
        return { status: "error", message: "Token Aktivasi Salah" };
      }
    } catch (e) {
      return {
        status: "error",
        message: "Token Aktivasi Tidak Valid",
      };
    }
  },
  [VERIFY_AUTH](context) {
    console.log("verifyauth");
    // console.log(state);
    if (state.token == "FFFF") {
      context.commit(PURGE_AUTH);
    }
  },
  [UNREGISTER](context) {
    localStorage.removeItem("mw_register_hash");
    localStorage.removeItem("mw_login_token");
    context.commit(UNREGISTER_AUTH);
  },
  [LOGOUT](context) {
    localStorage.removeItem("mw_login_token");
    context.commit(PURGE_AUTH);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_INIT_AUTH](state, { user, loginToken }) {
    state.isAuthenticated = false;
    state.user = user;
    state.loginToken = loginToken;
  },
  [SET_AUTH](state, loginToken) {
    state.isAuthenticated = true;
    state.loginToken = loginToken;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [UNREGISTER_AUTH](state) {
    state.isAuthenticated = false;
    state.user = defaultUser;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.loginToken = null;
  },
  updateSesionExpire(state, exp) {
    state.sessionExpire = exp;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
