const state = {
  coins: JSON.parse(localStorage.getItem("mw_coins")),
  coins_hotbit_bsc: JSON.parse(localStorage.getItem("mw_coins_hotbit_bsc")),
  coins_erc: JSON.parse(localStorage.getItem("mw_coins_erc")),
  coins_heco: JSON.parse(localStorage.getItem("mw_coins_heco")),
};

const getters = {
  listCoins(state) {
    return state.coins;
  },
  listCoins_hotbit_bsc(state) {
    return state.coins_hotbit_bsc;
  },
  listCoins_erc(state) {
    return state.coins_erc;
  },
  listCoins_heco(state) {
    return state.coins_heco;
  },
  listCoins_matcha_erc(state) {
    return state.coins_matcha_erc;
  },
};

const actions = {
  saveCoin(context, payload) {
    //cek duplikat
    let coins = state.coins;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins.push(data);
    } else {
      //update
      coins.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins", JSON.stringify(coins));
    context.commit("setCoins", coins);
  },
  updateAktifCoin(context, payload) {
    //cek duplikat
    let coins = state.coins;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins.push(data);
    } else {
      //update
      coins.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins", JSON.stringify(coins));
    context.commit("setCoins", coins);
  },
  updateAktifCoinErc(context, payload) {
    //cek duplikat
    let coins = state.coins_erc;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      ...coins[edit_target],
      ...payload.changes,
    };
    if (edit_target == -1) {
      //kosong
      coins.push(data);
    } else {
      //update
      coins.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_erc", JSON.stringify(coins));
    context.commit("setCoins_erc", coins);
  },
  deleteCoin(context, payload) {
    let coins = state.coins;
    if (!Array.isArray(coins)) {
      coins = [];
    }
    let search = payload;
    let del_target = coins.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins", JSON.stringify(coins));
    context.commit("setCoins", coins);
  },
  importCoin(context, payload) {
    localStorage.setItem("mw_coins", JSON.stringify(payload.coin));
    localStorage.setItem("mw_coins_hotbit_bsc", JSON.stringify(payload.coin_hotbit_bsc));
    localStorage.setItem("mw_coins_erc", JSON.stringify(payload.coin_erc));
    localStorage.setItem("mw_coins_heco", JSON.stringify(payload.coin_heco));
    context.commit("setCoins", payload.coin);
    context.commit("setCoins_hotbit_bsc", payload.coin_hotbit_bsc);
    context.commit("setCoins_erc", payload.coin_erc);
    context.commit("setCoins_heco", payload.coin_heco);
  },
  saveCoin_hotbit_bsc(context, payload) {
    //cek duplikat
    let coins_hotbit_bsc = state.coins_hotbit_bsc;
    if (!Array.isArray(coins_hotbit_bsc)) {
      coins_hotbit_bsc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_hotbit_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_hotbit_bsc.push(data);
    } else {
      //update
      coins_hotbit_bsc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_hotbit_bsc", JSON.stringify(coins_hotbit_bsc));
    context.commit("setCoins_hotbit_bsc", coins_hotbit_bsc);
  },
  deleteCoin_hotbit_bsc(context, payload) {
    let coins_hotbit_bsc = state.coins_hotbit_bsc;
    if (!Array.isArray(coins_hotbit_bsc)) {
      coins_hotbit_bsc = [];
    }
    let search = payload;
    let del_target = coins_hotbit_bsc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_hotbit_bsc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_hotbit_bsc", JSON.stringify(coins_hotbit_bsc));
    context.commit("setCoins_hotbit_bsc", coins_hotbit_bsc);
  },
  saveCoin_erc(context, payload) {
    //cek duplikat
    let coins_erc = state.coins_erc;
    if (!Array.isArray(coins_erc)) {
      coins_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_erc.push(data);
    } else {
      //update
      coins_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_erc", JSON.stringify(coins_erc));
    context.commit("setCoins_erc", coins_erc);
  },
  deleteCoin_erc(context, payload) {
    let coins_erc = state.coins_erc;
    if (!Array.isArray(coins_erc)) {
      coins_erc = [];
    }
    let search = payload;
    let del_target = coins_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_erc", JSON.stringify(coins_erc));
    context.commit("setCoins_erc", coins_erc);
  },
  saveCoin_heco(context, payload) {
    //cek duplikat
    let coins_heco = state.coins_heco;
    if (!Array.isArray(coins_heco)) {
      coins_heco = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_heco.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_heco.push(data);
    } else {
      //update
      coins_heco.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_heco", JSON.stringify(coins_heco));
    context.commit("setCoins_heco", coins_heco);
  },
  deleteCoin_heco(context, payload) {
    let coins_heco = state.coins_heco;
    if (!Array.isArray(coins_heco)) {
      coins_heco = [];
    }
    let search = payload;
    let del_target = coins_heco.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_heco.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_heco", JSON.stringify(coins_heco));
    context.commit("setCoins_heco", coins_heco);
  },
  saveCoin_matcha_erc(context, payload) {
    //cek duplikat
    let coins_matcha_erc = state.coins_matcha_erc;
    if (!Array.isArray(coins_matcha_erc)) {
      coins_matcha_erc = [];
    }
    let search = payload.edit == false ? payload.nama_coin : payload.original;
    let edit_target = coins_matcha_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    let data = {
      nama_coin: payload.nama_coin,
      coin_address: payload.coin_address,
      desimal: payload.desimal,
      aktif: payload.aktif != undefined ? payload.aktif : true,
    };
    if (edit_target == -1) {
      //kosong
      coins_matcha_erc.push(data);
    } else {
      //update
      coins_matcha_erc.splice(edit_target, 1, data);
    }
    localStorage.setItem("mw_coins_matcha_erc", JSON.stringify(coins_matcha_erc));
    context.commit("setCoins_matcha_erc", coins_matcha_erc);
  },
  deleteCoin_matcha_erc(context, payload) {
    let coins_matcha_erc = state.coins_matcha_erc;
    if (!Array.isArray(coins_matcha_erc)) {
      coins_matcha_erc = [];
    }
    let search = payload;
    let del_target = coins_matcha_erc.findIndex(item => {
      return item.nama_coin == search;
    });
    if (del_target != -1) {
      coins_matcha_erc.splice(del_target, 1);
    }
    localStorage.setItem("mw_coins_matcha_erc", JSON.stringify(coins_matcha_erc));
    context.commit("setCoins_matcha_erc", coins_matcha_erc);
  },
};

const mutations = {
  setCoins(state, coins) {
    state.coins = coins;
  },
  setCoins_hotbit_bsc(state, coins_hotbit_bsc) {
    state.coins_hotbit_bsc = coins_hotbit_bsc;
  },
  setCoins_erc(state, coins_erc) {
    state.coins_erc = coins_erc;
  },
  setCoins_heco(state, coins_heco) {
    state.coins_heco = coins_heco;
  },
  setCoins_matcha_erc(state, coins_matcha_erc) {
    state.coins_matcha_erc = coins_matcha_erc;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
