import CryptoJS from "crypto-js";

/**
 * Service to call HTTP request via Axios
 */
const CryptoService = {
  key:
    ([] + [] + [][[]])[+!+[] + (+!+[] + +!+[])] +
    `1` +
    `7` +
    `2` +
    ([] + [] + [][[]])[+!+[] + +!+[]] +
    ([] + [] + [][[]])[+!+[] + +!+[]] +
    `9` +
    `5` +
    (![] + [])[+[]] +
    `4` +
    (![] + [])[+[]] +
    ([] + [] + [][[]])[+!+[] + (+!+[] + +!+[])] +
    (typeof [])[+!+[]] +
    `2` +
    `1` +
    `4` +
    `1` +
    `2` +
    (![] + [])[+!+[]] +
    `6` +
    `9` +
    `2` +
    ([] + [] + [][[]])[+!+[] + (+!+[] + +!+[])] +
    `7` +
    `3` +
    `9` +
    `2` +
    `9` +
    `9` +
    `6` +
    `1` +
    ([] + [] + [][[]])[+!+[] + (+!+[] + +!+[])],

  encrypt(plaintext) {
    let encrypted = CryptoJS.AES.encrypt(plaintext, this.key);
    return encrypted.toString();
  },
  decrypt(cipher) {
    let encrypted = CryptoJS.AES.decrypt(cipher, this.key);
    return encrypted.toString(CryptoJS.enc.Utf8);
  },
  md5(plaintext) {
    return CryptoJS.MD5(plaintext).toString();
  },
  hmacsha256(key, payload) {
    return CryptoJS.HmacSHA256(payload, key);
  },
};

export default CryptoService;
