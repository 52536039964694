import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import users from "./users.module";
import coin from "./coin.module";
import setting from "./setting.module";
import binance from "./binance.module";
import binance_bnb from "./binance_bnb.module";
import binance_btc from "./binance_btc.module";
import hotbit from "./hotbit.module";
import huobi from "./huobi.module";
import sinyal from "./sinyal.module";
import sinyal_erc from "./sinyal_erc.module";
import sinyal_matcha_bsc from "./sinyal_matcha_bsc.module";
import sinyal_hotbit_matcha_bsc from "./sinyal_hotbit_matcha_bsc.module";
import sinyal_hotbit_1inch_bsc from "./sinyal_hotbit_1inch_bsc.module";
import sinyal_matcha_erc from "./sinyal_matcha_erc.module";
import sinyal_para_bsc from "./sinyal_para_bsc.module";
import sinyal_dodo_bsc from "./sinyal_dodo_bsc.module";
import sinyal_huobi1inch_erc from "./sinyal_huobi1inch_erc.module";
import sinyal_huobidodo_heco from "./sinyal_huobidodo_heco.module";
import sinyal_1inch_bnb_bsc from "./sinyal_1inch_bnb_bsc.module";
import sinyal_1inch_eth_erc from "./sinyal_1inch_eth_erc.module";
import sinyal_1inch_btc_bsc from "./sinyal_1inch_btc_bsc.module";
import sinyal_matcha_bnb_bsc from "./sinyal_matcha_bnb_bsc.module";
import sinyal_paraswap_bnb_bsc from "./sinyal_paraswap_bnb_bsc.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    users,
    coin,
    setting,
    binance,
    binance_bnb,
    binance_btc,
    huobi,
    hotbit,
    sinyal,
    sinyal_erc,
    sinyal_matcha_bsc,
    sinyal_hotbit_matcha_bsc,
    sinyal_hotbit_1inch_bsc,
    sinyal_matcha_erc,
    sinyal_para_bsc,
    sinyal_dodo_bsc,
    sinyal_huobi1inch_erc,
    sinyal_huobidodo_heco,
    sinyal_1inch_bnb_bsc,
    sinyal_1inch_btc_bsc,
    sinyal_1inch_eth_erc,
    sinyal_matcha_bnb_bsc,
    sinyal_paraswap_bnb_bsc,
  },
});
