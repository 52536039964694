const state = {
  settings: JSON.parse(localStorage.getItem("mw_settings")),
};

const getters = {
  listSettings(state) {
    return state.settings;
  },
};

const actions = {
  settingSet(context, payload) {
    let settings = { ...state.settings, [payload.name]: payload.value };
    localStorage.setItem("mw_settings", JSON.stringify(settings));
    context.commit("setSettings", settings);
  },
  setSetting(context, payload) {
    let settings = { ...state.settings, ...payload };
    localStorage.setItem("mw_settings", JSON.stringify(settings));
    context.commit("setSettings", settings);
  },
};

const mutations = {
  setSettings(state, payload) {
    state.settings = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
