// import axios from "axios";

let lsSinyalHotbitMatchaBsc = JSON.parse(localStorage.getItem("mw_sinyal_hotbit_matcha_bsc"));

const state = {
  sinyal: lsSinyalHotbitMatchaBsc != null ? lsSinyalHotbitMatchaBsc.sinyal : [],
  lastFetch: lsSinyalHotbitMatchaBsc != null ? lsSinyalHotbitMatchaBsc.lastFetch : null,
};

const getters = {
  listSinyalHotbitMatchaBsc(state) {
    return state.sinyal;
  },
  lastFetchSinyalHotbitMatchaBsc(state) {
    return state.lastFetch != null ? new Date(state.lastFetch).toLocaleString("id-ID") : "";
  },
};

const actions = {
  registerSinyalHotbitMatchaBsc(context) {
    let sinyal = [];
    console.log("sinyal", sinyal);
    context.rootState.coin.coins_hotbit_bsc.forEach(item => {
      let askPrice = context.rootGetters.getHotbitBookCoinPriceAsk(item.nama_coin);
      let bidPrice = context.rootGetters.getHotbitBookCoinPriceBid(item.nama_coin);
      sinyal.push({
        symbol: item.nama_coin,
        pair: askPrice.pair,
        modal_kiri: context.rootState.setting.settings.modal_kiri_hotbit_matcha_bsc || 400,
        modal_kanan: context.rootState.setting.settings.modal_kanan_hotbit_matcha_bsc || 400,
        coin_address: item.coin_address,
        binance_ask_price: askPrice.price,
        ask_xprice: askPrice.xprice !== undefined ? askPrice.xprice : null,
        binance_bid_price: bidPrice.price,
        bid_xprice: bidPrice.xprice !== undefined ? bidPrice.xprice : null,
        inch_usdt: 0,
        selisih_pnl_kiri: 0,
        selisih_pnl_kiri_persen: 0,
        inch_sum: 0,
        inch_price: 0,
        selisih_pnl_kanan: 0,
        selisih_pnl_kanan_persen: 0,
        aktif: item.aktif_matcha != undefined ? item.aktif_matcha : true,
      });
    });
    console.log(sinyal);
    context.commit("registerSinyalHotbitMatchaBsc", sinyal);
  },
  updateSinyalHotbitMatchaBsc(context, coin) {
    let sinyal = state.sinyal;
    let index = state.sinyal.findIndex(item => {
      return item.symbol == coin.symbol;
    });
    let symbol = sinyal[index];
    symbol = { ...symbol, ...coin.value };
    sinyal.splice(index, 1, symbol);
    context.commit("registerSinyalHotbitMatchaBsc", sinyal);
  },
  clearSinyalHotbitMatchaBsc(context) {
    let sinyal = state.sinyal.map(item => {
      return {
        ...item,
        binance_ask_price: "wait...",
        binance_bid_price: "wait...",
        inch_usdt: "wait...",
        selisih_pnl_kiri: "wait...",
        selisih_pnl_kiri_persen: "wait...",
        inch_sum: "wait...",
        inch_price: "wait...",
        selisih_pnl_kanan: "wait...",
        selisih_pnl_kanan_persen: "wait...",
      };
    });
    // sinyal.filter(item => {{
    // }});
    context.commit("registerSinyalHotbitMatchaBsc", sinyal);
  },
};

const mutations = {
  registerSinyalHotbitMatchaBsc(state, payload) {
    localStorage.setItem("mw_sinyal_hotbit_matcha_bsc", JSON.stringify({ sinyal: payload, lastFetch: Date.now() }));
    state.sinyal = payload;
    state.lastFetch = Date.now();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
